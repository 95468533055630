<template>
    <div>
        <b-overlay :show="loading">
    <b-card>
      <b-form @submit.prevent>
        <app-collapse>
          <app-collapse-item
            :is-visible="isVisible"
            title="Búsqueda"
          >
            <b-row>

              <b-col md="3">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Fecha desde
                  </h5>
                  <div class="input-group">
                    <flat-pickr
                      v-model="filter.fechaDesde"
                      class="form-control"
                      placeholder="Click para seleccionar..."
                      :config="config"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn-clear"
                        type="button"
                        title="Limpiar"
                        data-clear
                      >
                        <feather-icon
                          icon="XIcon"
                        />
                      </button>
                    </div>
                  </div>

                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Fecha Hasta
                  </h5>
                  <div class="input-group">
                    <flat-pickr
                      v-model="filter.fechaHasta"
                      class="form-control"
                      placeholder="Click para seleccionar..."
                      :config="config"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn-clear"
                        type="button"
                        title="Limpiar"
                        data-clear
                      >
                        <feather-icon
                          icon="XIcon"
                        />
                      </button>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Cursos
                  </h5>
                  <div class="form-label-group">
                    <b-form-group>
                      <autocomplete-infinity
                        id="curso"
                        url="dash/filtro/curso-normativo"
                        placeholder="Seleccione Curso"
                        label="description"
                        items-per-page="10"
                        @item-selected="selectAplicaciones"
                      />

                    </b-form-group>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <h5 class="font-weight-bold">
                     Sucursal
                  </h5>
                  <autocomplete-infinity
                    id="sucursal"
                    url="dash/filtro/sucursal"
                    placeholder="Seleccione Sucursal"
                    label="sucursal"
                    items-per-page="10"
                    @item-selected="selectSucursal"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <h5 class="font-weight-bold">
                     Area
                  </h5>
                  <autocomplete-infinity
                    id="area"
                    url="dash/filtro/area"
                    placeholder="Seleccione Area"
                    label="area"
                    items-per-page="10"
                    @item-selected="selectArea"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                >
                  <h5 class="font-weight-bold">
                    Rut / Nombre / Apellidos
                  </h5>

                  <b-form-input
                          id="alumno"
                          v-model="filter.alumno"
                          placeholder="Escriba el dato del Alumno a buscar"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <div class="text-right mt-2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    type="submit"
                    @click="buscar"
                  >

                    <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Buscar</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-form>
    </b-card>
    <b-card>
      <h4 class="card-title">
        Reportes Corona
      </h4>


      <div v-if="showTable">
        <b-row class="pr-1 pl-1">
          <b-col
            sm="12"
            md="6"
            class="my-1 text-right"
          >
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Mostrar</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                class="mx-50 col-md-2"
                @change="onChangePerPage"
              />
              <label>filas por página</label>
            </div>
          </b-col>
          <b-col
            sm="4"
            md="6"
            class="my-1"
          >
            <div class="d-flex justify-content-end">

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                type="submit"
                class="ml-1"
                @click="exportar"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span class="align-middle">Exportar</span>

              </b-button>
            </div>
          </b-col>
          <b-col
            v-if="showTable"
            cols="12"
          >
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :per-page="perPage"
              :items="listado"
              :fields="fields"
              responsive
              class="mb-0"
              show-empty
              :busy="loadingTable"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(nombre)="data">
                {{ data.item.nombre  ? data.item.nombre : '-'}}
              </template>
              <template #cell(apellidos)="data">
                {{ data.item.apellidos  ? data.item.apellidos : '-'}}
              </template>
              <template #cell(rut)="data">
                {{ data.item.rut  ? data.item.rut : '-'}}
              </template>
              <template #cell(genero)="data">
                {{ data.item.descripcion_genero  ? data.item.descripcion_genero : '-'}}
              </template>
              <template #cell(Correo)="data">
                {{ data.item.correo1  ? data.item.correo1 : '-'}}
              </template>
              <template #cell(aplicacion)="data" >

                {{ data.item.aplicacion  ? data.item.aplicacion : '-'}}
              </template>
              <template #cell(estado_inscripcion)="data">
                <b-badge v-if="data.item.estado_inscripcion"
                  class="center"
                  :variant="estado_inscripcion(data.item.estado_inscripcion)"
                >
                  {{ data.item.estado_inscripcion }}
                </b-badge>
                <div v-else  class="center" >
                  -
                </div>
              </template>
              <template #cell(porciento_avance)="data">
                <b-badge
                    class="center"
                    :variant="porciento_avance(data.item.porciento_avance)"
                >
                  {{ data.item.porciento_avance }}
                </b-badge>
              </template>
              <template #cell(evaluacion_nota)="data">
                {{ data.item.evaluacion_final_nota  ? data.item.evaluacion_final_nota : '-'}}
              </template>
              <template #cell(fecha_evaluacion)="data">
                {{ data.item.evaluacion_final_fecha  ? data.item.evaluacion_final_fecha : '-'}}
              </template>
              <template #cell(fecha_primer_ingreso_curso)="data">
                {{ data.item.fecha_primer_ingreso_curso  ? data.item.fecha_primer_ingreso_curso : '-'}}
              </template>
              <template #cell(area)="data">
                {{ data.item.area  ? data.item.area : '-'}}
              </template>
              <template #cell(sucursal)="data">
                {{ data.item.sucursal  ? data.item.sucursal : '-'}}
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>

          </b-col>
        </b-row>

        <b-row class="pl-1">
          <b-col
            cols="8"
            class="mt-3"
          >
            <div v-if="totalRows > 0">
              <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
            </div>
          </b-col>

          <b-col cols="4">
            <b-pagination
              :total-rows="totalRows"
              :value="page"
              :per-page="perPage"
              align="right"
              class="mt-3 mr-1"
              aria-controls="my-table"
              @change="onPageChange"
            />
          </b-col>

        </b-row>
      </div>
    </b-card>
  </b-overlay>

    </div>
</template>

<script>
import {
  BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BButton, BFormRadio,
  BFormCheckbox, BTooltip, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ModalDetalleSeguimiento from '@/components/ModalDetalleSeguimiento'

export default {
  name: 'ReportesSucursalArea',

  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    flatPickr,
    BFormRadio,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    BTooltip,
    BSpinner,
    'modal-ds': ModalDetalleSeguimiento,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showTable: false,
      perPage: 50,
      reportType: 1,
      currentPageV: 1,
      page: 1,
      list: [],
      fields: [
        {key: 'nombre', label: 'Nombre', display: true},
        {key: 'apellidos', label: 'Apellidos', display: true},
        {key: 'rut', label: 'Rut', display: true},
        {key: 'correo1', label: 'Correo', display: true},
        {key: 'descripcion_genero', label: 'Genero', display: true},
         {key: 'aplicacion', label: 'Aplicacion', display: true},
        {key: 'horas_curso', label: 'Horas Curso', display: true},
        {key: 'estado_evaluacion', label: 'Estado', display: true},
        {key: 'porciento_avance', label: '% Avance', display: true},
        {key: 'evaluacion_final_nota', label: 'Evaluación Final Nota', display: true},
        {key: 'evaluacion_final_fecha', label: 'Evaluación Final Fecha', display: true},
        {key: 'fecha_inicio', label: 'Fecha Inicio ', display: true},
        {key: 'fecha_cierre', label: 'Fecha Cierre', display: true},
        {key: 'sucursal', label: 'Sucursal', display: true},
        {key: 'area', label: 'Area', display: true},
        ],
      isVisible: true,
      filter: {
        fechaDesde: '01-04-2022',
        fechaHasta: '04-04-2023',
        type: 3,
        aplicacion: '',
        sucursal: '',
        area: '',
        alumno: '',
      },
      sortField: 'nombre',
      showColumn: false,
      showColumnText: 'Mostrar Columnas',
      pageOptions: [50, 100, 500, 1000],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    totalRows() {
      return this.$store.state.reporteCorona.rowsTotal
    },
    to() {
      return this.$store.state.reporteCorona.to
    },
    from() {
      return this.$store.state.reporteCorona.from
    },
    listado() {
      return this.$store.state.reporteCorona.list
    },
    currentPage() {
      return this.$store.state.reporteCorona.currentPage
    },
    loading() {
      return this.$store.state.reporteCorona.loading
    },
    loadingTable() {
      return this.$store.state.reporteCorona.loadingTable
    },


    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },

  },
  mounted() {
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    buscar() {

        this.getList()
        this.showTable = true

    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onChangePerPage() {
      this.page = 1
      this.getList()
    },
    getList() {
      const filterData = {
        type: 3,
        fecha_desde: this.filter.fechaDesde,
        fecha_hasta: this.filter.fechaHasta,
        id_aplicacion: this.filter.aplicacion,
        sort_by: this.sortField,
        page: this.page,
        perPage: this.perPage,
        area: this.filter.area,
        sucursal: this.filter.sucursal,
        alumno: this.filter.alumno,
      }
      this.$store.dispatch('reporteCorona/getList', filterData)
    },


    porciento_avance(value) {
      const number = parseFloat(value)
      if (number < 50) {
        return 'light-danger'
      } if (number >= 50 && number < 100) {
        return 'light-warning'
      } if (number >= 100) {
        return 'light-success'
      }
    },
    estado_inscripcion(value) {
      if (value == 'Eliminado') {
        return 'light-danger'
      } if (value == 'Inscrito') {
        return 'light-success'
      }
    },
    exportar() {
      if (this.listado.length == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const fieldsV = []
      Object.entries(this.fields).forEach(entry => {
        const [key, value] = entry
        if (value.display) {
          fieldsV.push(value.key)
        }
      })
      const filterData = {
        type: 3,
        fecha_desde: this.filter.fechaDesde,
        fecha_hasta: this.filter.fechaHasta,
        id_aplicacion: this.filter.aplicacion,
        sort_by: this.sortField,
        page: this.page,
        perPage: this.perPage,
        area: this.filter.area,
        sucursal: this.filter.sucursal,
        alumno: this.filter.alumno,
      }

        this.$store.dispatch('reporteCorona/exportData', filterData)
      //}
    },
    selectAplicaciones(aplicaciones) {
      this.filter.aplicacion = aplicaciones != null ? aplicaciones.id : ''
    },
    selectSucursal(sucursal) {
      this.filter.sucursal = sucursal != null ? sucursal.id_sucursal : ''
    },
    selectArea(area) {
      this.filter.area = area != null ? area.id_area : ''
    },

  },
}
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .demo-inline-spacing.curso .custom-radio{
        margin-top: 0.5rem!important;

    }
    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
    [dir=ltr] .demo-inline-spacing.checkbox-inline > *{
        margin-right: 0rem;
    }
</style>
